
import Detail from "@/views/course/child/Detail.vue";
import Upsert from "@/views/course/child/Upsert.vue";

import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "DetailEditCourse",
  props: {
    courseIdProp: {
      type: Number,
      required: true,
    },
  },
  components: {
    Detail,
    Upsert,
  },
  setup(props) {
    const isEdit = ref<boolean>(false);
    const react = ref(1);
    const courseId = computed(() => {
      return react.value > 0 ? props.courseIdProp : 0;
    });

    const editCourse = () => {
      isEdit.value = true;
    };

    const onUpdateSuccess = () => {
      react.value++;
      isEdit.value = false;
    };

    return {
      react,
      isEdit,
      editCourse,
      courseId,
      onUpdateSuccess,
    };
  },
});
