<!-- Demo5Draggable.vue -->
<template>
  <h1>Cấu trúc bài học</h1>

  <div class="alert alert-primary d-flex align-items-center p-5 mb-10">
    <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
      <inline-svg src="media/icons/duotune/general/gen048.svg" />
    </span>
    <div class="d-flex flex-column">
      <h4 class="mb-1 text-primary">Hướng dẫn</h4>
      <span
        >Kéo thả bài học để sắp xếp cấu trúc, đề mục sẽ được điều chỉnh tự
        động<br />
        Lưu ý: Cấu trúc các bài học lồng nhau tối đa 3 cấp độ
      </span>
    </div>
  </div>

  <Tree
    ref="treeCourse"
    :value="fullTreeData"
    :indent="50"
    v-slot="{ node, path, tree }"
  >
    <span class="tree-element">
      <b class="btn-fold" @click="tree.toggleFold(node, path)">
        {{ node.$folded ? "+" : "-" }}
      </b>
      {{ generatePath(path) + " " + node.text }}
    </span>
  </Tree>

  <div style="padding: 25px" class="text-center">
    <button
      class="btn btn-lg btn-primary mx-10"
      type="button"
      @click="submit()"
    >
      OK
    </button>
    <button class="btn btn-lg btn-primary mx-10" type="button" @click="reset()">
      Reset
    </button>
  </div>
</template>

<style>
span.tree-element {
  font-weight: bold;
  font-size: 16px;
}

b.btn-fold {
  padding-right: 8px;
  padding-left: 8px;
}

b.btn-fold:hover {
  cursor: pointer;
}
</style>

<script>
import "he-tree-vue/dist/he-tree-vue.css";
import { Tree, Draggable, Fold } from "he-tree-vue";
import { clearTreeDataWithLessonName } from "@/core/helpers/tree-data.helper.ts";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { CourseActions } from "@/store/vm/course/enums";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  components: { Tree: Tree.mixPlugins([Draggable, Fold]) },
  props: {
    courseIdProp: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  methods: {
    generatePath: function (path) {
      let txt = "";
      for (let i = 0; i < path.length; i++) {
        txt += path[i] + 1 + ".";
      }
      return txt;
    },
  },
  setup(props) {
    const store = useStore();
    const treeCourse = ref();

    const react = ref(1);
    const courseId = computed(() => {
      return react.value > 0 ? props.courseIdProp : 0;
    });

    const fullTreeData = computed(() => {
      return store.state.CourseModule.treeDataLesson;
    });

    watch(courseId, (currentValue) => {
      init(currentValue);
    });

    const init = (courseId) => {
      store.dispatch(CourseActions.GET_STRUCT, courseId).catch((e) => {
        console.log(e);
      });
    };

    onMounted(() => {
      init(props.courseIdProp);
    });

    const reset = () => {
      init(props.courseIdProp);
    };

    const submit = async () => {
      const data = treeCourse.value.getPureTreeData();
      const neatData = clearTreeDataWithLessonName(data);

      const stepResult = await store.dispatch(CourseActions.SET_STRUCT, {
        courseId: courseId.value,
        treeData: JSON.stringify(neatData),
      });

      if (stepResult.isSuccess) {
        Swal.fire({
          position: "top-end",
          text: "Success!",
          icon: "success",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          position: "top-end",
          text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
          icon: "error",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    };

    return { courseId, fullTreeData, submit, treeCourse, react, reset };
  },
};
</script>
