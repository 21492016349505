
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { CourseActions } from "@/store/vm/course/enums";

export default defineComponent({
  name: "detail-course",
  props: {
    courseIdProp: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const courseId = computed(() => {
      return props.courseIdProp;
    });

    const formRef = ref<null | HTMLFormElement>(null);
    const content = ref<string>("");

    const defaultData = {
      course_id: "",
      name: "",
      description: "",
      type_code: "online",
      status: 0,
      detail: "",
      images: "",
      document: "",
      video: "",
      url: "",
      price: 0,
    };

    onMounted(async () => {
      // get detail
      if (courseId.value) {
        const detail = await store
          .dispatch(CourseActions.GET_DETAIL, courseId.value)
          .catch((e) => {
            console.log(e);
          });

        formData.value = detail;
        content.value = detail.detail;
      }
    });

    const formData = ref({ ...defaultData });

    const statusLabels = [
      {
        value: 0,
        label: "Active",
      },
      {
        value: 1,
        label: "Inactive",
      },
    ];

    const typeCodeLabels = [
      {
        value: "online",
        label: "Chương trình học online",
      },
      {
        value: "offline",
        label: "Chương trình học offline",
      },
      {
        value: "video",
        label: "Khóa học video dựng sẵn",
      },
    ];

    const rules = ref({});

    return {
      defaultData,
      formData,
      formRef,
      rules,
      content,
      statusLabels,
      typeCodeLabels,
      courseId,
    };
  },
});
