
import List from "@/views/lesson/List.vue";
import Upsert from "@/views/lesson/child/Upsert.vue";

import { computed, defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { LessonActions } from "@/store/vm/lesson/enums";

export default defineComponent({
  name: "lesson-index",
  props: {
    courseIdProp: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  components: {
    List,
    Upsert,
  },
  setup(props) {
    const store = useStore();
    const lessonIdTarget = ref<number | boolean>(false);
    const isUpsert = ref<boolean>(false);

    const react = ref(1);
    const courseId = computed(() => {
      return react.value > 0 ? props.courseIdProp : 0;
    });

    const onDel = (lessonId) => {
      Swal.fire({
        title: "Bạn muốn xóa bài học?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(lessonId);
          console.log("resDel");
          console.log(resDel);
        }
      });
    };

    const actionDelete = async (lessonId) => {
      return store.dispatch(LessonActions.DELETE, lessonId).catch((e) => {
        console.log(e);
      });
    };

    const onUpsert = async (lessonId: number | boolean) => {
      lessonIdTarget.value = lessonId;
      isUpsert.value = true;
    };

    const onCreateSuccess = async () => {
      reset();
    };

    const onUpdateSuccess = async () => {
      reset();
    };

    const onCancel = async () => {
      reset();
    };

    const reset = () => {
      react.value++;
      isUpsert.value = false;
    };

    return {
      onDel,
      courseId,
      lessonIdTarget,
      onUpsert,
      isUpsert,
      onCreateSuccess,
      onUpdateSuccess,
      onCancel,
    };
  },
});
