import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  class: "card",
  style: {"padding-top":"50px","padding-bottom":"30px"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "badge bg-success",
  style: {"margin-left":"50px"}
}
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_List = _resolveComponent("List")!
  const _component_Upsert = _resolveComponent("Upsert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isUpsert)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_List, {
            courseIdProp: _ctx.courseId,
            onUpsert: _ctx.onUpsert,
            onDel: _ctx.onDel,
            "widget-classes": "mb-5 mb-xl-8"
          }, null, 8, ["courseIdProp", "onUpsert", "onDel"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isUpsert)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", null, [
              (_ctx.lessonIdTarget)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Cập nhật bài học "))
                : _createCommentVNode("", true),
              (_ctx.lessonIdTarget)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, " #" + _toDisplayString(_ctx.lessonIdTarget), 1))
                : _createCommentVNode("", true),
              (!_ctx.lessonIdTarget)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Thêm mới bài học"))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_component_Upsert, {
            lessonIdProp: _ctx.lessonIdTarget,
            courseIdProp: _ctx.courseId,
            onCreateSuccess: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCreateSuccess())),
            onUpdateSuccess: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onUpdateSuccess())),
            onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onCancel()))
          }, null, 8, ["lessonIdProp", "courseIdProp"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}