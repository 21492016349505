
import { computed, defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { StepResult } from "@/store/captain/CaptainModule";
import TinyMCE from "@/components/editor/tinyMCE.vue";
import { LessonActions, LessonGetters } from "@/store/vm/lesson/enums";

export default defineComponent({
  name: "upsert-course",
  components: {
    tiny: TinyMCE,
  },
  props: {
    courseIdProp: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
    lessonIdProp: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["createSuccess", "updateSuccess", "cancel"],
  methods: {
    activateCropper: function () {
      (this.$refs["imagesCropper"] as any).selectFile();
    },
    onUploadCoverFail: function (response) {
      Swal.fire({
        position: "top-end",
        text: "Đã xảy ra lỗi upload ảnh, vui lòng thử lại sau!",
        icon: "error",
        buttonsStyling: false,
        timer: 2000,
        width: 400,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    // Get params from url
    const courseId = computed(() => {
      return props.courseIdProp;
    });

    const lessonId = computed(() => {
      return props.lessonIdProp;
    });

    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const content = ref<string>("");
    const videoSrcLocal = ref<string>("");

    const defaultData = {
      number_shifts: 0,
      name: "",
      type: "online",
      video: "",
      content: "",
    };

    onMounted(async () => {
      // get detail
      if (lessonId.value) {
        const detail = store.getters[LessonGetters.GET_BY_ID](lessonId.value);
        formData.value = detail;
        // extra
        content.value = detail.content;
      } else {
        formData.value.number_shifts = 0;
        formData.value.name = "";
        formData.value.type = "online";
        formData.value.video = "";
        formData.value.content = "";
        content.value = "";
      }
    });

    const formData = ref({ ...defaultData });

    const lessonTypeLabels = [
      {
        value: "online",
        label: "Online",
      },
      {
        value: "offline",
        label: "Offline",
      },
      {
        value: "document",
        label: "Tài liệu",
      },
      {
        value: "video",
        label: "Video",
      },
    ];

    const rules = ref({});
    const isCreate = !lessonId.value;

    const actionCreate = (par) => {
      par.content = content.value;
      par.course_id = courseId.value;
      return store.dispatch(LessonActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      par.content = content.value;
      par.lesson_id = lessonId.value;
      par.course_id = courseId.value;
      return await store.dispatch(LessonActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const onChangeContent = (newValue) => {
      formData.value.content = newValue;
      content.value = newValue;
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;
          if (isCreate) {
            stepResult = await actionCreate(formData.value);
          } else {
            stepResult = await actionUpdate(formData.value);
          }

          loading.value = false;
          if (stepResult.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(() => {
              if (isCreate) {
                emit("createSuccess");
              } else {
                emit("updateSuccess");
              }
            });
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    const actionCancel = () => {
      emit("cancel");
    };

    const onChangeVideoSrc = (embedUrl) => {
      // Handle validate embed link
      videoSrcLocal.value = embedUrl;
    };

    return {
      courseId,
      isCreate,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      content,
      onChangeContent,
      onChangeVideoSrc,
      videoSrcLocal,
      lessonTypeLabels,
      actionCancel,
    };
  },
});
