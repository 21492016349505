
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import DetailEditCourse from "@/views/course/child/DetailEdit.vue";
import IndexLesson from "@/views/lesson/Index.vue";
import CourseStruct from "@/views/course/child/Struct.vue";
import { getFocusNavItem } from "@/core/helpers/dom.ts";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "course-compose",
  props: {},
  components: {
    DetailEditCourse,
    IndexLesson,
    CourseStruct,
  },
  setup() {
    const route = useRoute();
    const courseId = parseInt(route.params["courseId"] as string);
    const focusingTab = ref("");

    onMounted(() => {
      setCurrentPageBreadcrumbs("Chương trình học", ["Đào tạo"]);
      MenuComponent.reinitialization();
    });

    const changeTab = (event) => {
      focusingTab.value = getFocusNavItem(event);
    };

    return {
      courseId,
      focusingTab,
      changeTab,
    };
  },
});
