
import { LessonActions, LessonMutations } from "@/store/vm/lesson/enums";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "lesson-list",
  components: {},
  props: {
    courseIdProp: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["upsert", "del"],
  data() {
    return {
      loadingModule: true,
    };
  },
  setup(props, { emit }) {
    const store = useStore();
    const checked = ref(false);
    const { t } = useI18n();

    const react = ref(1);
    const courseId = computed(() => {
      return react.value > 0 ? props.courseIdProp : 0;
    });

    watch(courseId, (currentValue) => {
      store.commit(LessonMutations.SET_COURSE_ID, currentValue);
      store.dispatch(LessonActions.GET_ALL_LESSON_BY_COURSE_ID).catch((e) => {
        console.log(e);
      });
    });

    onMounted(() => {
      store.commit(LessonMutations.SET_COURSE_ID, courseId.value);
      store.dispatch(LessonActions.GET_ALL_LESSON_BY_COURSE_ID).catch((e) => {
        console.log(e);
      });
    });

    const list = computed(() => {
      return store.state.LessonModule.list;
    });

    const count = computed(() => {
      return store.state.LessonModule.list.length;
    });

    const upsertLesson = (lessonId) => {
      emit("upsert", lessonId);
    };

    const delLesson = (lessonId) => {
      emit("del", lessonId);
    };

    const lang = {
      edit: t("common.edit"),
      delete: t("common.delete"),
    };

    const lessonTypeLabels = [
      {
        value: "online",
        label: "Online",
      },
      {
        value: "offline",
        label: "Offline",
      },
      {
        value: "document",
        label: "Tài liệu",
      },
      {
        value: "video",
        label: "Video",
      },
    ];

    const transformLessonType = (lessonType) => {
      const res = lessonTypeLabels.filter((m) => m.value === lessonType);
      return res[0].label;
    };

    return {
      list,
      checked,
      count,
      upsertLesson,
      delLesson,
      lang,
      transformLessonType,
    };
  },
});
